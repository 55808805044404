// app/javascript/controllers/trix_toolbar_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toolbar"];

  connect() {
    const toolbar = this.toolbarTarget;
    const trixEditor = toolbar.editor;

    toolbar.addEventListener("trix-toolbar-insert", (event) => {
      const button = document.createElement("button");
      button.setAttribute("data-trix-action", "x-h2");
      button.textContent = "H2";
      button.addEventListener("click", () => this.toggleH2(event));
      event.target.appendChild(button);
    });
  }

  toggleH2(event) {
    event.preventDefault();
    const { trixEditor } = this.toolbarTarget;
    trixEditor.activateAttribute("x-h2");
  }
}
